<template>
  <div>
    <v-card class="request-card">
      <v-flex class="d-flex align-center">
        <div class="main-heading">
          <img src="@/assets/images/fork.svg" />
          Restaurant
        </div>
        <div class="ml-auto">
          <v-chip class="i-chip green">Completed</v-chip>
        </div>
      </v-flex>
      <v-flex class="d-flex align-center mt-5">
        <v-chip class="i-chip grey label-date">
          <img src="@/assets/images/calendar.svg" class="mr-2" />
          Tue, 21 Sep 19:30
        </v-chip>
        <div class="ml-auto">
          <v-chip class="i-chip outline">
            <img src="@/assets/images/user.svg" class="mr-2" />
            6
          </v-chip>
        </div>
      </v-flex>
      <v-flex class="d-flex align-center mt-5">
        <div class="location-label">
          <img src="@/assets/images/location.svg" class="mr-2" />
          Hakkasan Mayfair
        </div>
      </v-flex>
      <v-flex class="d-flex align-center">
        <div class="location-sub-label">17 Bruton St, London W1J 6QB</div>
      </v-flex>
      <v-flex class="d-flex align-center">
        <div>
          <template v-for="(item, index) in cuisines">
            <v-chip class="i-chip outline mr-2" :key="index">
              {{ item }}
            </v-chip>
          </template>
        </div>
      </v-flex>
      <v-flex class="d-flex align-center mt-5">
        <div class="main-heading">Special Requests</div>
        <div class="ml-auto">
          <v-icon>mdi-plus</v-icon>
        </div>
      </v-flex>
      <v-flex class="d-flex align-center mt-5">
        <v-btn block class="btn-black-outline">View Request</v-btn>
      </v-flex>
      <v-flex class="d-flex align-center mt-5">
        <div class="details-label">Managed by</div>
        <div class="ml-auto">
          <div class="details-name">
            <img alt="Avatar" src="@/assets/images/avatar.png" height="20px" />
            Dan Williams
          </div>
        </div>
      </v-flex>
    </v-card>
  </div>
</template>
<script>
export default {
  name: "RequestCompleted",
  data: () => ({
    cuisines: ["French", "Thai", "Vegetarian"],
  }),
};
</script>