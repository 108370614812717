<template>
  <div>
    <TheHeader />
    <div class="bg-gradient h-100">
      <v-container>
        <div class="mx-auto body-wrap">
          <h4 v-if="!Thankyou" class="login-title">Hello, David</h4>
          <v-card
            v-if="!Thankyou"
            class="mx-auto member-card"
            max-width="344"
            outlined
          >
            <v-flex class="d-flex align-center">
              <div class="heading">David Williams</div>
              <div class="ml-auto">
                <v-chip class="i-chip black">lifestyle</v-chip>
              </div>
            </v-flex>
            <v-flex class="d-flex align-center">
              <div class="text-caption">
                <img alt="Trophy" src="@/assets/images/trophy.svg" />
                5 Year Member
              </div>
            </v-flex>
            <v-flex class="d-flex align-center pt-12">
              <div class="member-since">Member since</div>
              <div class="ml-auto date">July 2018</div>
            </v-flex>
          </v-card>
          <div v-if="Thankyou">
            <h4 class="login-title">Thankyou David !</h4>
          </div>
          <div class="d-flex align-center justify-center help-text pt-12">
            <span v-if="!Thankyou">How can we help?</span>
            <span v-if="Thankyou">Your Request has been Submitted</span>
          </div>
          <div class="d-flex align-center justify-center pt-5 home-btn-wrap">
            <v-bottom-sheet v-model="sheet" inset content-class="elevation-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="btn-home btn-black" v-bind="attrs" v-on="on"
                  >Open New Request</v-btn
                >
              </template>
              <v-sheet class="text-center open-request">
                <v-stepper v-model="e6" horizontal class="mt-5 request-stepper">
                  <div class="d-flex">
                    <v-icon @click="sheet = !sheet">mdi-close</v-icon>
                    <div class="i-border"></div>
                  </div>
                  <div>
                    <h3 class="text-center">Hi, David,</h3>
                  </div>
                  <v-stepper-content
                    step="1"
                    style="border-left: 0px !important"
                  >
                    <v-card class="step-card">
                      <h4>How can we be of service today?</h4>
                      <v-row class="step1">
                        <template v-for="(item, index) in items">
                          <v-col class="pa-0 mr-2" cols="12" :key="index">
                            <v-btn
                              class="ma-2 list-service text-capitalize"
                              outlined
                              tile
                              block
                            >
                              <img :src="item.icon" class="mr-4" />
                              {{ item.text }}
                            </v-btn>
                          </v-col>
                        </template>
                      </v-row>
                    </v-card>
                    <div class="btn-wrap">
                      <v-btn color="primary" @click="e6 = 2"> Continue </v-btn>
                      <v-btn text> Cancel </v-btn>
                    </div>
                  </v-stepper-content>

                  <v-stepper-content
                    step="2"
                    style="border-left: 0px !important"
                  >
                    <v-card class="mb-12 step-card">
                      <v-icon>mdi-silverware-fork-knife</v-icon>
                      <h4>Who is the Resaurant Booking for ?</h4>
                      <v-text-field
                        placeholder="Name On Booking"
                      ></v-text-field>
                      <v-btn text> David Smith </v-btn>
                    </v-card>
                    <div class="btn-wrap">
                      <v-btn color="primary" @click="e6 = 3"> Continue </v-btn>
                      <v-btn text> Cancel </v-btn>
                    </div>
                  </v-stepper-content>

                  <v-stepper-content
                    step="3"
                    style="border-left: 0px !important"
                  >
                    <v-card class="mb-12 step-card">
                      <h4>What is the total party size ?</h4>
                      <div style="margin-top: 10px">
                        <v-btn class="mr-2 pa-0 ma-2" outlined> 1 </v-btn>
                        <v-btn class="mr-2 pa-0 ma-2" outlined> 2 </v-btn>
                        <v-btn class="mr-2 pa-0 ma-2" outlined> 3 </v-btn>
                        <v-btn class="mr-2 pa-0 ma-2" outlined> 4 </v-btn>
                        <v-btn class="mr-2 pa-0 ma-2" outlined>
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </div>
                    </v-card>
                    <div class="btn-wrap">
                      <v-btn color="primary" @click="e6 = 4"> Continue </v-btn>
                      <v-btn text> Cancel </v-btn>
                    </div>
                  </v-stepper-content>

                  <v-stepper-content
                    step="4"
                    style="border-left: 0px !important"
                  >
                    <v-card class="mb-12 step-card">
                      <h4>Preferred date & time:</h4>
                      <div style="margin-top: 10px">
                        <v-btn outlined>
                          <v-chip class="i-chip grey label-date">
                            <img
                              src="@/assets/images/calendar.svg"
                              class="mr-2"
                            />
                            Tue, 21 Sep 19:30
                          </v-chip>
                        </v-btn>
                      </div>
                      <div style="margin-top: 10px">
                        <v-btn text> Today </v-btn>
                        <v-btn text> Tomorrow </v-btn>
                      </div>
                    </v-card>
                    <div class="btn-wrap">
                      <v-btn color="primary" @click="e6 = 5"> Continue </v-btn>
                      <v-btn text> Cancel </v-btn>
                    </div>
                  </v-stepper-content>

                  <v-stepper-content
                    step="5"
                    style="border-left: 0px !important"
                  >
                    <v-card class="mb-12 step-card">
                      <h4>What Cuisine? (up to 3)</h4>

                      <v-btn class="mr-2 pa-4 ma-4 text-capitalize" outlined
                        >French</v-btn
                      >
                      <v-btn class="mr-2 pa-4 ma-4 text-capitalize" outlined
                        >Japanese</v-btn
                      >
                      <v-btn class="mr-2 pa-4 ma-4 text-capitalize" outlined
                        >Thai</v-btn
                      >

                      <v-btn class="mr-2 pa-4 ma-4 text-capitalize" outlined
                        >Steak</v-btn
                      >
                      <v-btn class="mr-2 pa-4 ma-4 text-capitalize" outlined
                        >Italian</v-btn
                      >
                      <v-btn class="mr-2 pa-4 ma-4 text-capitalize" outlined
                        >Vegetarian</v-btn
                      >

                      <v-btn text>I don't Mind</v-btn>
                    </v-card>
                    <div class="btn-wrap">
                      <v-btn color="primary" @click="e6 = 6"> Continue </v-btn>
                      <v-btn text> Cancel </v-btn>
                    </div>
                  </v-stepper-content>

                  <v-stepper-content
                    step="6"
                    style="border-left: 0px !important"
                  >
                    <v-card class="mb-12 step-card">
                      <h4>Anything notes you'd like to add ?</h4>
                      <v-text-field
                        placeholder="Special Requests, etc"
                      ></v-text-field>
                    </v-card>
                    <div class="btn-wrap">
                      <v-btn
                        color="primary"
                        @click="(sheet = !sheet), (Thankyou = true)"
                      >
                        Submit Request
                      </v-btn>
                      <v-btn text> Cancel </v-btn>
                    </div>
                  </v-stepper-content>
                </v-stepper>
              </v-sheet>
            </v-bottom-sheet>
            <v-btn href="/all-requests" class="btn-home btn-outline" outlined
              >View All Requests</v-btn
            >
          </div>
        </div>
      </v-container>
      <TheFooter />
    </div>
  </div>
</template>
<script>
import TheHeader from "../components/TheHeader";
import TheFooter from "../components/TheFooter";

export default {
  name: "Home",
  data: () => ({
    sheet: false,
    items: [
      {
        icon: require("../assets/images/fork.svg"),
        text: "Restaurant",
      },
      {
        icon: require("../assets/images/bar.svg"),
        text: "Bar",
      },
      {
        icon: require("../assets/images/night-club.svg"),
        text: "Night Club",
      },
      {
        icon: require("../assets/images/member-club.svg"),
        text: "Members Club",
      },
      {
        icon: require("../assets/images/hotel.svg"),
        text: "Hotel",
      },
      {
        icon: require("../assets/images/private-dining.svg"),
        text: "Private Dining",
      },
      {
        icon: require("../assets/images/corporate.svg"),
        text: "Private/Corporate Event",
      },
      {
        icon: require("../assets/images/ticket.svg"),
        text: "Ticket",
      },
      {
        icon: require("../assets/images/other.svg"),
        text: "Other",
      },
      {
        icon: require("../assets/images/some.svg"),
        text: "Recommend Something",
      },
    ],
    model: [1],
    e6: 1,
    Thankyou: false,
  }),
  components: {
    TheHeader,
    TheFooter,
  },
};
</script>
<style lang="scss" scoped>
.list-service {
  justify-content: flex-start;
  border-radius: 4px;
  height: 50px !important;
  font-size: 16px;
}
.step1 {
  margin: 10px;
}
.btn-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 0px;
}

.request-stepper {
  max-width: 600px;
  position: relative;
  margin: auto;
  box-shadow: 0 0 0 0 !important;
  text-align: left !important;

  .step-card {
    box-shadow: 0 0 0 0 !important;
  }
}

.open-request {
  height: 90vh !important;
  max-width: 800px;
  position: relative;
  margin: auto;
  box-shadow: 0 0 0 0 !important;
}

.v-bottom-sheet.v-dialog {
  box-shadow: 0 0 0 0 !important;
}
</style>