<template>
  <div>
    <TheHeaderBeforeLogin />
    <div class="bg-gradient h-100">
      <div class="container">
       <Login />
      </div>
      <TheFooter />
    </div>
  </div>
</template>
<script>
import TheHeaderBeforeLogin from '../components/TheHeaderBeforeLogin'
import TheFooter from '../components/TheFooter'
import Login from '../components/Login'

export default {
  name: 'Home',

  components: {
    TheHeaderBeforeLogin,
    TheFooter,
    Login
  },
}
</script>