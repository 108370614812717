<template>
  <div>
    <v-app-bar absolute class="nav-header" app height="95px" dark>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ma-2" text icon color="white" v-bind="attrs" v-on="on">
            <v-icon>mdil-menu</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-list-item-title @click="login">Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>
      <div class="d-flex align-center justify-content-center">
        <a href="/home" v-if="title !== 'Login'">
          <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="../assets/images/logo.svg"
            transition="scale-transition"
        /></a>
        <v-img
          v-else
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="../assets/images/logo.svg"
          transition="scale-transition"
        />
      </div>
      <v-spacer></v-spacer>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ma-2" text icon color="white" v-bind="attrs" v-on="on">
            <v-icon>mdil-bell</v-icon>
          </v-btn>
        </template>
        <v-card class="mx-auto" max-width="400" tile>
          <v-list>
            <v-list-item two-line>
              <v-list-item-title>Request Update</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  data: () => ({
    title: "",
  }),
  watch: {
    $route: function (val) {
      this.title = val.name;
    },
  },
  mounted() {
    this.loadTitle();
  },
  methods: {
    loadTitle() {
      this.title = this.$router.currentRoute.name;
    },
    login() {
      this.$router.push({ name: "Login" });
    },
  },
};
</script>