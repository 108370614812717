<template>
  <div>
    <TheHeader />
    <div class="bg-half-gradient h-100">
      <v-container fluid class="pa-0 overflow-hidden">
        <div class="mx-auto body-wrap">
          <h4 class="login-title">Your Requests</h4>
        </div>
        <div class="i-box pb-5">
          <v-row>
            <v-col cols="12">
              <div class="i-border"></div>
            </v-col>
          </v-row>
          <div class="content-wrap">
            <v-row class="pt-5">
              <v-col cols="12" md="4" sm="4" class="d-flex align-center">
                <v-row class="btn-request-group">
                  <v-col cols="6" md="6" sm="6" class="pa-0">
                    <v-btn
                      @click="open = true"
                      block
                      text
                      class="btn-request"
                      :class="open ? 'bg-white' : ''"
                      >Open</v-btn
                    >
                  </v-col>
                  <v-col cols="6" md="6" sm="6" class="pa-0">
                    <v-btn
                      @click="open = false"
                      block
                      text
                      class="btn-request"
                      :class="!open ? 'bg-white' : ''"
                      >Closed</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="8" sm="8">
                <v-text-field
                  filled
                  rounded
                  prepend-inner-icon="mdi-magnify"
                  autocomplete="off"
                  label="Search for a request..."
                  hide-details
                  class="search-input"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-divider></v-divider>
        <div class="content-wrap bg-grey pt-15">
          <v-row v-if="open">
            <v-col
              cols="12"
              md="6"
              lg="3"
              xl="3"
              sm="6"
              v-for="item in 10"
              :key="item"
            >
              <RequestNewCard />
            </v-col>
          </v-row>
          <v-row v-if="!open">
            <v-col
              cols="12"
              md="6"
              lg="3"
              xl="3"
              sm="6"
              v-for="item in 10"
              :key="item"
            >
              <RequestCompletedCard />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </div>
    <TheFooter />
  </div>
</template>
<script>
import TheHeader from "../components/TheHeader";
import TheFooter from "../components/TheFooter";
import RequestNewCard from "../components/RequestNewCard";
import RequestCompletedCard from "../components/RequestCompletedCard";

export default {
  name: "Home",

  components: {
    TheHeader,
    TheFooter,
    RequestNewCard,
    RequestCompletedCard,
  },
  data: () => ({
    open: true,
    cuisines: ["French", "Thai", "Vegetarian"],
  }),
};
</script>